<template>
  <div>
    <div v-for="v in menus" :key="v.name">
      <Submenu v-if="v.children" :name="v.name" :key="v.name">
        <template slot="title">
          <div class="menu-content">
            <span
              v-if="v.meta.icon"
              :class="`icon icon-${v.meta.icon}`" />
            <span class="name">
              {{v.meta.name}}
            </span>
          </div>
        </template>
        <i-menu :menus="v.children || {}" />
      </Submenu>
      <MenuItem v-else :name="v.name" :key="v.name" :to="{ name: v.name }">
        <div class="menu-content">
          <span
            v-if="v.meta.icon"
            :class="`icon icon-${v.meta.icon}`" />
          <span class="name">
            {{v.meta.name}}
          </span>
        </div>
      </MenuItem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'iMenu',
  props: {
    menus: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.menu-content {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 6px;
    font-size: 20px;
  }
}
</style>
