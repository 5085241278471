<template>
  <Layout class="layout">
    <Sider
      v-model="isCollapsed"
      class="side-bar"
      ref="sideBar"
      hide-trigger
      collapsible
      :collapsed-width="78">
      <Header class="header brand">
        快视科技后台管理
      </Header>
      <Menu
        :active-name="activeName"
        accordion
        :class="['menu', isCollapsed && 'collapsed-menu']"
        width="auto">
        <i-menu :menus='menus'/>
      </Menu>
    </Sider>
    <Layout>
      <Header class="header">
        <div class="bar-left">
<!--          <Icon-->
<!--            :class="['collapse-icon', isCollapsed && 'retract']"-->
<!--            type="md-menu"-->
<!--            size="24"-->
<!--            @click="onToggleCollapse" />-->
          <h3>{{channelName||storeName}}</h3>
        </div>
        <div class="bar-right">
          <div class="user-info">
            <Avatar
              class="avatar"
              :title="nickName">
              {{ avatarText }}
            </Avatar>
            <span class="nick-name">{{ nickName }}</span>
          </div>
          <span class="logout">
          <Icon
            @click="onLogout"
            type="md-exit"/>
        </span>
        </div>
      </Header>
      <Content :class="`${useEmptyLayout ? '' : 'content'}`">
        <h2 v-show="!useEmptyLayout" class="nav">{{ title }}</h2>
        <router-view v-if="isRouterAlive"/>
      </Content>
    </Layout>
  </Layout>
</template>

<script>
import Menu from '../menu';
import RouterUtil from '../../utils/router.util';

export default {
  name: 'layout',
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {
    iMenu: Menu,
  },
  computed: {
    menus() {
      return this.$store.getters.menus;
    },
    activeName() {
      return this.$route.name;
    },
    nickName() {
      return this.$store.getters.nickName;
    },
    avatarText() {
      return this.nickName[0] || 'U';
    },
    title() {
      return this.$route.meta?.name || '';
    },
    useEmptyLayout() {
      return !!this.$route.meta?.useEmptyLayout;
    },
    channelName() {
      return this.$store.getters.channelName;
    },
    storeName() {
      return this.$store.getters.storeName;
    },
  },
  data() {
    return {
      isCollapsed: false,
      isRouterAlive: true,
    };
  },
  methods: {
    onToggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    onLogout() {
      this.$store.commit('removeToken');
      this.$store.commit('removeNickName');
      // window.location.reload();
      RouterUtil.resetRouter();
      this.$router.push(`/login/${this.$store.getters.userType}`);
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  background: #f5f7f9;
  position: relative;
  overflow: hidden;
}

.header.brand {
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  background-color: #00284d;
  color: #fff;
  border-bottom: 1px solid #001529;
}

.side-bar {
  overflow: auto;
  background-color: #001529;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  color: rgba(0, 0, 0, 0.647058823529412);
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.bar-left,
.bar-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.bar-left{
  margin-left: 20px;
}
.collapse-icon {
  margin-left: 16px;
  height: 24px;
  width: 40px;
  cursor: pointer;
  border-radius: 2px;
  transition: all ease .3s;
  &.retract {
    transform: rotate(-90deg);
  }
}

.bar-right > * {
  margin-right: 20px;
}

.user-info .nick-name {
  margin-left: 6px;
}

.avatar {
  cursor: pointer;
  color: #fff;
  background-color: #2161eb;
}

.logout {
  display: inherit;
  cursor: pointer;
  font-size: 26px;
  transition: color linear .3s;

  &:hover {
    color: #ffb7b7;
  }

  &:active, &:focus {
    color: #d49797;
  }
}

/deep/ .menu {
  background-color: transparent;
  &:after {
    width: 0;
  }
  .name, .icon, .ivu-icon {
    color: rgba(255, 255, 255, 0.647058823529412);
  }
  a.ivu-menu-item {
    &::after {
      width: 0 !important;
    }
    &.ivu-menu-item-active {
      background: #2161eb;
    }
  }
  li.ivu-menu-submenu {
    &.ivu-menu-item-active {
      div.ivu-menu-submenu-title {
        background: #2161eb;
        .name, .icon, .ivu-icon {
          color: rgba(255, 255, 255, 0.647058823529412);
        }
      }
      a.ivu-menu-item {
        &.ivu-menu-item-active {
          background: transparent;
          .name, .icon, .ivu-icon {
            color: #2161eb;
          }
        }
      }
    }
  }
}

.content {
  margin: @spacingMD;
  padding: @spacingLG;
  background-color: #fff;
  border-radius: 4px;
  overflow: auto;
}

.nav {
  margin-bottom: @spacingMD;
}
</style>
